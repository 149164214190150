import { createWebHistory, createRouter } from "vue-router";
import Login from "./components/Login.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const BoardDevices = () => import("./components/BoardDevices.vue")
const BoardDevice = () => import("./components/BoardDevice.vue")
const Register = () => import("./components/Register.vue")
const EmulatorProducts = () => import("./components/Device/EmulatorProducts.vue")
const PusherTest = () => import("./components/Device/PusherTest.vue")

const routes = [
    {
        path: "/",
        component: Login,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/signup",
        name: "register",
        // lazy-loaded
        component: Register,
    },
    {
        path: "/profile",
        name: "profile",
        // lazy-loaded
        component: Profile,
    },
    {
        path: "/admin",
        name: "admin",
        // lazy-loaded
        component: BoardAdmin,
    },
    {
        path: "/mod",
        name: "moderator",
        // lazy-loaded
        component: BoardModerator,
    },
    {
        path: "/user",
        name: "user",
        // lazy-loaded
        component: BoardUser,
    },
    {
        path: "/devices",
        name: "devices",
        // lazy-loaded
        component: BoardDevices,
    },
    {
        path: "/devices/:uniqueId",
        name: "Device",
        component: BoardDevice,
    },

    //emulator routes
    {
        path: "/device-emulator/products",
        name: "device-emulator-products",
        // lazy-loaded
        component: EmulatorProducts,
    },

    {
        path: "/device-emulator/pusher-test",
        name: "device-emulator-pusher-test",
        // lazy-loaded
        component: PusherTest,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
