import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = '/api/auth/';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(API_URL + 'signup',
            {
                name: user.name,
                email: user.email,
                password: user.password
            },
            {
                headers: authHeader()
            }
        );
    }
}

export default new AuthService();
