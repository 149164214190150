<template>
    <div id="app">
        <nav class="navbar navbar-expand navbar-dark bg-dark">
            <a href="/" class="navbar-brand">
                trueBarman<b v-if="showAdminBoard" class="text-color-red">Admin</b><b v-if="showDeviceBoard" class="text-color-orange">Emulator</b>
            </a>
            <div v-if="showAdminBoard" class="navbar-nav mr-auto">
                <li class="nav-item">
                    <router-link to="/devices" class="nav-link">Devices</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/user" class="nav-link">Users</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/signup" class="nav-link">Register</router-link>
                </li>
            </div>

            <div v-if="showBarBoard" class="navbar-nav mr-auto">
                <li class="nav-item">
                    In development...
                </li>
            </div>

            <div v-if="showDeviceBoard" class="navbar-nav mr-auto">
                <li class="nav-item">
                    <router-link to="/device-emulator/products" class="nav-link">Products</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/device-emulator/pusher-test" class="nav-link">TestPusher</router-link>
                </li>
            </div>

            <div v-if="!currentUser" class="navbar-nav ml-auto">
                <li class="nav-item">
                    <router-link to="/login" class="nav-link">
                        <font-awesome-icon icon="sign-in-alt"/>
                        Login
                    </router-link>
                </li>
            </div>

            <div v-if="currentUser" class="navbar-nav ml-auto">
                <li v-if="showDeviceBoard">
                    <a class="orange-text-important nav-link" target="_blank" href="https://api.postman.com/collections/3843662-f65488f3-41ac-46f6-9c4d-83b62553e451?access_key=PMAT-01H8P30ETS8EPYTAG9JJKD1WVF">Postman Collection</a>
                </li>
                <li class="nav-item">
                    <router-link to="/profile" class="nav-link">
                        <font-awesome-icon icon="user"/>
                        {{ currentUser.email }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click.prevent="logOut">
                        <font-awesome-icon icon="sign-out-alt"/>
                        LogOut
                    </a>
                </li>
            </div>
        </nav>

        <div class="container">
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        showAdminBoard() {
            if (this.currentUser && this.currentUser['roles']) {
                return this.currentUser['roles'].includes('admin');
            }

            return false;
        },
        showBarBoard() {
            if (this.currentUser && this.currentUser['roles']) {
                return this.currentUser['roles'].includes('bar');
            }

            return false;
        },
        showDeviceBoard() {
            if (this.currentUser && this.currentUser['roles']) {
                return this.currentUser['roles'].includes('device');
            }

            return false;
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
};
</script>

<style>
    .orange-text-important {
        color: rgb(255, 140, 60) !important;
    }

    .orange-text-important:hover {
        text-decoration: none !important;
        color: rgb(150, 90, 10) !important;
    }

    .text-color-red {
        color: red;
    }

    .text-color-orange {
        color: rgb(255, 140, 60);
    }
</style>
